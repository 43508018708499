#root,
html,
body {
  height: 100vh;
}

body {

  margin: 0;
  background-color: whitesmoke;

}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}